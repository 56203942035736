module.exports = [
  {
    locale: 'fr',
    label: 'Français',
    default: true,
  },
  {
    locale: 'en',
    label: 'English',
  },
];
